<template>
    <div>
      <v-app class="desktopLayoutClass">
        <div>
          <v-row justify="center">
            <v-dialog v-model="dependent_success" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break: normal">
                   {{$t("Customer.AddDependent.Dependent_Added_Successfully")}}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" @click="closeDependentSuccess()">
                    {{$t("Warning_Message.Ok")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
        
        <div>
          <v-row justify="center">
            <v-dialog v-model="auth_error_enable" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break: normal">
                   {{$t("Warning_Message.Session_Expired_Please_Login_Again")}}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    depressed
                    @click="closeDialogUnauthorized"
                  >
                    {{$t("Warning_Message.Login")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
  
        <div>
          <v-row justify="center">
            <v-dialog v-model="error_enable" persistent max-width="290">
              <v-card>
                <v-card-title style="word-break: normal">
                   {{$t("Warning_Message.Something_Went_Wrong")}}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" @click="closeDialogError">
                    {{$t("Warning_Message.Ok")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
        
        <div>
            <v-row justify="center">
                <v-dialog v-model="ageLimitModal" persistent max-width="290">
                <v-card>
                    <v-card-title style="word-break:normal">
                    {{$t("Warning_Message.Invalid_Dependent_Please_Add_Them_Through_My_Linked_Contacts")}}
                    </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" depressed @click="closeageLimitModal">
                        {{$t("Warning_Message.Ok")}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </div>
  
        <div>
            <v-row justify="center">
                <v-dialog v-model="phoneNumber_check" persistent max-width="290">
                <v-card>
                    <v-card-title style="word-break:normal">
                    {{ $t("Warning_Message.Invalid_Phonenumber") }}
                    </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" depressed @click="closePhoneNumberModal">
                        {{$t("Warning_Message.Ok")}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </div>
  
        <div>
            <v-row justify="center">
                <v-dialog v-model="error_enable" persistent max-width="290">
                <v-card>
                    <v-card-title style="word-break:normal">
                    {{$t("Warning_Message.Something_Went_Wrong")}}
                    </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed
            color="primary" @click="closeDialogError">
                    {{$t("Warning_Message.Ok")}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </div>
        
        <div>
          <v-row justify="center">
            <v-dialog
              v-model="height_weight_error_enable"
              persistent
              max-width="290"
            >
              <v-card>
                <v-card-title style="word-break: normal">
                  {{$t("Warning_Message.Height_Weight_Cannot_Be_Above_999")}}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    color="primary"
                    @click="closeHeightWeightDialogError"
                  >
                     {{$t("Warning_Message.Ok")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
  
        <div>
          <v-row justify="center">
            <v-dialog
              v-model="required_fields_error_enable"
              persistent
              max-width="290"
            >
              <v-card>
                <v-card-title style="word-break: normal">
                  {{$t("Warning_Message.Please_Fill_All_Details")}}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    color="primary"
                    @click="closeRequiredDialogError"
                  >
                     {{$t("Warning_Message.Ok")}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
        
        <div class="mainDivaddDependent">
            <div
                v-show="show_header"
                class="header"
                style="display: flex; flex-direction: row; padding-top: 10px; padding-left: 5px;"
            >
                <div style="display: flex; flex-direction: row; width: 100%">
                    <div style="width: 90%" align="left">
                        <span class="light-blue-color-text font-weight-semi-bold" style="font-size: 15pt">{{ $t("Customer.AddDependent.Add_Dependent") }}</span>
                    </div>
                    <div style="width: 10%; padding-top: 6px" align="right" v-on:click="redirectToNurseBooking()">
                        <img src="https://s3iconimages.mymedicine.com.mm/cancelButton.svg"/>
                    </div>
                </div>
            </div>

            <div class="PageClass" style="padding: 10px 20px !important; height:92vh; overflow:scroll;margin-top:25px;">
            <p style="text-align: left; padding-bottom: 0px; margin-bottom: 0px; margin-left: 10px; margin-top: 7px; font-weight: 600 !important;">
                {{ $t("Customer.AddDependent.Relationship") }}<span style="color:red">*</span>
            </p>
            <v-select
                v-if="$i18n.locale=='en'"
                :items="Relationship_list"
                v-model="dependent_relationship"
                placeholder="Relationship"
                style="width: 100%; border-radius: 10px; margin-right: 10px; margin-left: 0px; margin-top: 7px;"
                outlined
                item-text = "en_text"
                return-object
                background-color="#F4F6FA"
                :rules="relationshipRules"
            >
            </v-select>
            <v-select
                v-if="$i18n.locale=='mm'"
                :items="Relationship_list"
                v-model="dependent_relationship"
                placeholder="တော်စပ်မှု"
                style="width: 100%; border-radius: 10px; margin-right: 10px; margin-left: 0px; margin-top: 7px;"
                outlined
                item-text = "mm_text"
                return-object
                background-color="#F4F6FA"
                :rules=" relationshipRulesMM"
    
            >
            </v-select>
            <p style="text-align: left; padding-bottom: 0px; margin-bottom: 0px; margin-left: 10px; margin-top: 7px; font-weight: 600 !important;">
                {{ $t("Customer.AddDependent.Name") }}<span style="color:red">*</span>
            </p>
            <v-text-field
                v-model="dependent_name"
                outlined
                :placeholder="translation_enter_name"
                style="width: 100%; border-radius: 10px; margin-right: 10px; margin-left: 0px; margin-top: 7px;"
                background-color="#F4F6FA"
                :rules="$store.state.locale == 'en' ? nameRules : nameRulesMM"
            />
            <p style="text-align: left; padding-bottom: 0px; margin-bottom:0px; margin-left: 10px; margin-top: 7px; font-weight: 600 !important;">
                {{ $t("Customer.AddDependent.Date_Of_Birth") }}<span style="color:red">*</span>
            </p>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                v-model="dependent_dob"
                :placeholder="translation_dob"
                background-color="#F4F6FA"
                :rules="$store.state.locale == 'en' ? dobRules : dobRulesMM"
                outlined
                v-bind="attrs"
                v-on="on"
                style="border-radius:8px;"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="dependent_dob"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                min="1900-01-01"
                full-width
                @change="get_age(dependent_dob), menu= false"
                style="400px !important"
            ></v-date-picker>
            </v-menu>
            <p style="text-align: left; padding-bottom: 0px; margin-bottom: 0px; margin-left: 10px; margin-top: 7px; font-weight: 600 !important;">
                {{ $t("Customer.AddDependent.Gender") }}<span style="color:red">*</span>
            </p>
            <v-select
                v-if="$i18n.locale=='en'"
                :items="gender"
                v-model="dependent_gender"
                :rules="genderRules"
                outlined
                placeholder="Gender"
                item-text = "en_text"
                background-color="#F4F6FA"
                return-object
                style="width: 100%; border-radius: 10px; margin-right: 10px; margin-left: 0px; margin-top: 7px;"
            >
            </v-select>
            <v-select
                v-if="$i18n.locale=='mm'"
                :items="gender"
                v-model="dependent_gender"
                :rules="genderRulesMM"
                outlined
                placeholder="ကျား/မ"
                return-object
                item-text = "mm_text"
                background-color="#F4F6FA"
                style="width: 100%; border-radius: 10px; margin-right: 10px; margin-left: 0px; margin-top: 7px;"
            >
            </v-select>
            <p style="text-align: left; padding-bottom: 0px; margin-bottom: 0px; margin-left: 10px; margin-top: 7px; font-weight: 600 !important;">
                {{ $t("Customer.AddDependent.Email") }}
            </p>
            <v-text-field
            v-model="dependent_email"
            outlined
            :placeholder="translation_enter_email"
            style="width: 100%;
                border-radius: 10px;
                margin-right: 10px;
                margin-left010px;
                margin-top: 7px;"
            background-color="#F4F6FA"
            />
            <p style="text-align: left; padding-bottom: 0px; margin-bottom: 0px; margin-left: 10px; margin-top: 7px; font-weight: 600 !important;">
                {{ $t("Customer.AddDependent.Phone_Number") }}
            </p>
            <v-text-field
                v-model="dependent_phoneNumber"
                outlined
                :placeholder="translation_enter_number"
                :rules="$store.state.locale == 'en' ? mobileNumberRules : mobileNumberRulesMM"
                type="number"
                style="width: 100%; border-radius: 10px; margin-right: 10px; margin-left: 0px; margin-top: 7px;"
                background-color="#F4F6FA"
            />
            <p style="text-align: left; padding-bottom: 0px; margin-bottom: 0px; margin-left: 10px; margin-top: 7px; font-weight: 600 !important;">
                {{ $t("Customer.AddDependent.NRC") }}
            </p>
            <v-text-field
                v-model="dependent_nrc"
                outlined
                :placeholder="translation_enter_nrc"
                style="width: 100%; border-radius: 10px; margin-right: 10px; margin-left: 0px; margin-top: 7px;"
                background-color="#F4F6FA"
            />
            <v-row>
                <v-col cols="12">
                    <p style="text-align: left; padding-bottom: 0px; margin-bottom: 0px; margin-left: 10px; margin-top:7px; font-weight: 600 !important;">
                        {{ $t("Customer.AddDependent.Height") }}
                    </p>
                    <div style="display:flex; felx-direction:row;">
                        <v-text-field
                        v-model="heightinFt"
                        type="number"
                        outlined
                        :placeholder="translation_feet"
                        :rules="$store.state.locale == 'en' ? heightRules : heightRulesMM"
                        style="border-radius: 10px;margin-top:10px;"
                        background-color="#F4F6FA"
                        />
                        <p style="margin-top:auto; margin-bottom: auto;padding: 0px 5px;">{{ $t("Customer.EditProfile.Feet") }}</p>
                        <v-text-field
                            v-model="heightinIn"
                            type="number"
                            outlined
                            :placeholder="translation_inches"
                            :rules="$store.state.locale == 'en' ? heightRulesIn : heightRulesInMM"
                            style="border-radius: 10px;margin-top:10px;margin-left:5px"
                            background-color="#F4F6FA"
                        />
                        <p style="margin-top:auto; margin-bottom: auto;padding: 0px 5px;">{{ $t("Customer.EditProfile.Inches") }}</p>
                    </div>
                </v-col>
                <v-col cols="12">
                <p style="text-align: left; padding-bottom: 0px; margin-bottom: 0px; margin-left: 10px; margin-top: 7px;font-weight: 600 !important;">
                    {{ $t("Customer.AddDependent.Weight") }}
                </p>
                <v-text-field
                    v-model="dependent_weight"
                    outlined
                    :placeholder="translation_in_lbs"
                    type="number"
                    background-color="#F4F6FA"
                    class="flexbox"
                    style="margin-top: 7px"
                    :rules="$store.state.locale == 'en' ? weightRules : weightRulesMM"
                />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                <p style="text-align: left; padding-bottom: 0px; margin-bottom: 0px; margin-left: 10px; margin-top: 10px; font-weight: 600 !important;">
                    {{ $t("Customer.AddDependent.Alcoholic") }}
                </p>
                <v-select
                v-if="$i18n.locale=='en'"
                    v-model="dependent_alcoholic"
                    :items="alcoholicValueList"
                    item-text = "en_text"
                    outlined
                    class="flex-item"
                    background-color="#F4F6FA"
                    style="margin-top: 10px"
                    return-object
                />
                <v-select
                v-if="$i18n.locale=='mm'"
                    v-model="dependent_alcoholic"
                    :items="alcoholicValueList"
                    item-text = "mm_text"
                    outlined
                    class="flex-item"
                    background-color="#F4F6FA"
                    style="margin-top: 10px"
                    return-object
                />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                <p style="text-align: left; padding-bottom: 0px; margin-bottom: 0px; margin-left: 10px; margin-top: 10px; font-weight: 600 !important;">
                    {{ $t("Customer.AddDependent.Smoker") }}
                </p>
                <v-select
                    v-if="$i18n.locale=='en'"
                    v-model="dependent_smoker"
                    :items="smokerValueList"
                    outlined
                    background-color="#F4F6FA"
                    class="flexbox"
                    style="margin-top: 10px"
                    item-text = "en_text"
                    return-object
                />
                <v-select
                    v-if="$i18n.locale=='mm'"
                    v-model="dependent_smoker"
                    :items="smokerValueList"
                    outlined
                    background-color="#F4F6FA"
                    class="flexbox"
                    style="margin-top: 10px; padding:5px;line-height: 20px !important;"
                    item-text = "mm_text"
                    return-object
                />
                </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                style="width: 98%; height: 48px; margin-bottom: 10%; align: center;"
                @click="saveDependentInfo()"
                :disabled="isSaved || !(dependent_relationship != '' && dependent_name != ''  && dependent_gender !='' && dependent_dob !='') || ageLimitNotInRange"
            >
                {{ $t("Customer.AddDependent.Save_Dependent") }}
            </v-btn>
            </div>
        </div>
      </v-app>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import { axios_auth_instance_nurseBooking } from '../../utils/axios_utils';

  export default {
    name: "NBACustomerAddDependent",
    data() {
      return {
        dependent_name: "",
        menu: false,
        dependent_phoneNumber: "",
        dependent_height: "",
        dependent_email: "",
        dependent_dob: "",
        dependent_weight: null,
        heightinFt:"",
        heightinIn:"",
        dependent_gender: "",
        dependent_smoker: "",
        dependent_alcoholic: "",
        ageLimitModal : false,
        ageLimitNotInRange : false,
        dependent_nrc: "",
        submit_button: "",
        gender: [{"key":"male","mm_text":"ကျား","en_text":"Male"},{"key":"female","mm_text":"မ","en_text":"Female"},{"key":"other","mm_text":"အခြား","en_text":"Other"}],
        Relationship_list: [{"key":"parent","mm_text":"မိဘ","en_text":"Parent"},{"key":"child","mm_text":"သားသမီး","en_text":"Child"},{"key":"spouse","mm_text":"အိမ်ထောင်ဖက်","en_text":"Spouse"},{"key":"sibling","mm_text":"မောင်နှမ","en_text":"Sibling"}],
        dependent_relationship: "",
        customer_id: "",
        customer_name: "",
        phoneNumber: "",
        currentCustomer: "",
        dependent_success: false,
        auth_error_enable: false,
        error_enable: false,
        height_weight_error_enable: false,
        required_fields_error_enable: false,
        smokerValueList: [{"key":"yes","mm_text":"သောက်သည်","en_text":"Yes"},{"key":"no","mm_text":"မသောက်ပါ","en_text":"No"}],
        alcoholicValueList: [{"key":"non_drinker","mm_text":"အရက်လုံးဝမသောက်ပါ","en_text":"Non drinker"},{"key":"social_drinker","mm_text":"လူမှုရေးအရသောက်ပါသည်","en_text":"Social drinker"},{"key":"moderate_drinker","mm_text":"အလွန်အကြူးမသောက်ပါ","en_text":"Moderate drinker"},{"key":"alcoholic","mm_text":"အရက်စွဲနေပါသည်","en_text":"Alcoholic"}],
        relationshipRules: [(v) => !!v || "Relationship is required"],
        relationshipRulesMM: [(v) => !!v || "တော်စပ်ပုံထည့်သွင်းရန် လိုအပ်ပါသည်။"],
        nameRules: [(v) => !!v || "Name is required"],
        nameRulesMM: [(v) => !!v || "အမည်ထည့်သွင်းရန် လိုအပ်ပါသည်။"],
        nrcRules: [(v) => !!v || "NRC is required"],
        genderRules: [(v) => !!v || "Gender is required"],
        genderRulesMM: [(v) => !!v || "ကျား/မထည့်သွင်းရန် လိုအပ်ပါသည်။"],
        emailRules: [(v) => !!v || "Email is required"],
        dobRules: [(v) => !!v || "DOB is required"],
        dobRulesMM: [(v) => !!v || "မွေးနေ့ထည့်သွင်းရန် လိုအပ်ပါသည်။"],
        heightRules: [
          (v) => (v && v < 10) || "Height cannot be more than 10 feet",
        ],
        heightRulesMM: [
          (v) => (v && v < 10) || "အရပ်သည် ၁၀ ပေ အောက်ဖြစ်သင့်ပါသည်။",
        ],
        heightRulesIn: [(v) => v<=12 || "Inches cannot be Greater than 12"],
        heightRulesInMM: [(v) => v<=12 || "လက်မသည် ၁၂ လက်မအောက်ဖြစ်ရပါမည်။"],
        weightRules: [
          // (v) => !!v || "Weight is required",
          (v) => ((v && v.length <= 3) || v == '1000') || "Weight cannot be more than 1000 lbs",
        ],
        weightRulesMM: [
          // (v) => !!v || "Weight is required",
          (v) => ((v && v.length <= 3) || v == '1000') || "အလေးချိန်သည် ပေါင် ၁၀၀၀ ထပ်ပိုများသောပမာဏ ဖြည့်သွင်း၍မရပါ။",
        ],
        mobileNumberRules: [
  
          v => (v && v.length >=7 && v.length <= 11) || 'Mobile Number should be between 7 to 11 numbers',
          v => v > 0 || 'Mobile number cannot be negative',
        ],
        mobileNumberRulesMM: [
  
          v => (v && v.length >=7 && v.length <= 11) || 'ဖုန်းနံပါတ်၏ ဂဏန်းအရေအတွက်မှာ ၇ လုံးမှ ၁၁ လုံးဖြစ်ရပါမည်။',
          v => v > 0 || 'ဖုန်းနံပါတ်တွင် အနုတ် (-) ထည့်၍ မရပါ',
        ],
        isMobile: false,
        isSaved: false,
        translation_enter_name: "Enter Name",
        translation_dob: "Date Of Birth",
        translation_enter_email: (this.$i18n.locale == "en") ? "Enter Email" : "အီးလ်မေး",
        translation_enter_number: (this.$i18n.locale == "en") ? "Enter Phone Number" : "ဖုန်းနံပါတ်",
        translation_enter_nrc: (this.$i18n.locale == "en") ? "Enter NRC" : "မှတ်ပုံတင်",
        translation_in_lbs: (this.$i18n.locale == "en") ? "in lbs" : "ပေါင်",
        translation_feet: (this.$i18n.locale == "en") ? "feet" : "ပေ",
        translation_inches: (this.$i18n.locale == "en") ? "inches" : "လက်မ",
        show_header: true,
        phoneNumber_check:false,
      };
    },
    methods: {
      closePhoneNumberModal(){
        this.phoneNumber_check = false
         this.isSaved = false;
      },
      closeageLimitModal(){
        this.ageLimitModal =false;
      },
      get_age(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
            this.ageLimitNotInRange = false;
      },
      closeDialogUnauthorized() {
        this.auth_error_enable = false;
        this.$router.push({
          name: "Login",
        });
      },
      closeDependentSuccess() {
        this.dependent_success = false;
        this.$router.push({
            path: '/nurseBooking'
        });
      },
      closeHeightWeightDialogError() {
        this.height_weight_error_enable = false;
      },
      closeRequiredDialogError() {
        this.required_fields_error_enable = false;
      },
      closeDialogError() {
        this.error_enable = false;
      },
      redirectToNurseBooking() {
        this.$router.go(-1);
      },
      saveDependentInfo() {
        this.isSaved = true;
        if (this.dependent_name && this.dependent_relationship && this.dependent_dob && this.dependent_gender) {
          if ( this.dependent_weight < 999) {
           this.dependent_height = this.heightinFt +"\'"+ this.heightinIn + "\"";
            var dependent_body = {
                token: this.currentCustomer,
                typeOfUser: "CUSTOMER",
                dependentName: this.dependent_name,
                dependentCustomerRelation: this.dependent_relationship.en_text,
                dependentDob: this.dependent_dob,
                dependentHeight: this.dependent_height ? this.dependent_height : '',
                dependentWeight: this.dependent_weight ? Number(this.dependent_weight) : 0,
                dependentGender: this.dependent_gender.en_text,
                dependentEmail: this.dependent_email ? this.dependent_email : '',
                dependentNrc: this.dependent_nrc ? this.dependent_nrc : '',
                dependentPhoneNumber: this.dependent_phoneNumber ? this.dependent_phoneNumber : this.customer_name != "Guest" ? this.phoneNumber : "",
                dependentAlcoholic: this.dependent_alcoholic.en_text ? this.dependent_alcoholic.en_text : '',
                dependentSmoker: this.dependent_smoker.en_text ? this.dependent_smoker.en_text : '',
            };
            // console.log(dependent_body);
            if(this.dependent_phoneNumber)
            {
              if(this.dependent_phoneNumber.toString().length >= 7 && this.dependent_phoneNumber.toString().length <=11)
              {
                axios_auth_instance_nurseBooking
                    .post("/customer/dependent", dependent_body)
                    .then((saveDependentInfoResponse) => {
                        var dependent_object = saveDependentInfoResponse.data.data;
                        this.dependent_success = true;

                        this.dependent_name = "";
                        this.dependent_relationship = "";
                        this.dependent_dob = "";
                        this.heightinIn = "";
                        this.heightinFt="";
                        this.dependent_weight = null;
                        this.dependent_gender = "";
                        this.dependent_alcoholic = "";
                        this.dependent_smoker = "";
                        this.dependent_phoneNumber = "";
                        this.dependent_nrc = "";
                        this.dependent_email = "";
                        this.dependent_phoneNumber = "";
                    })
                    .catch((saveDependentInfoError) => {
                        if (saveDependentInfoError.response.status == 401) {
                            this.auth_error_enable = true;
                        } else {
                            this.error_enable = true;
                            this.isSaved = false;
                        }
                    });
              }
              else {
                this.phoneNumber_check = true  
              }
            }
            else
            {
                axios_auth_instance_nurseBooking
                  .post("/customer/dependent", dependent_body)
                  .then((saveDependentInfoResponse) => {
                    var dependent_object = saveDependentInfoResponse.data.data;
                    this.dependent_success = true;
 
                    this.dependent_name = "";
                    this.dependent_relationship = "";
                    this.dependent_dob = "";
                    this.heightinIn = "";
                    this.heightinFt="";
                    this.dependent_weight = null;
                    this.dependent_gender = "";
                    this.dependent_alcoholic = "";
                    this.dependent_smoker = "";
                    this.dependent_phoneNumber = "";
                    this.dependent_nrc = "";
                    this.dependent_email = "";
                    this.dependent_phoneNumber = "";
                  })
                  .catch((saveDependentInfoError) => {
                    if (saveDependentInfoError.response.status == 401) {
                      this.auth_error_enable = true;
                    } else {
                      this.error_enable = true;
                      this.isSaved = false;
                    }
                });
            }
          } else {
            this.height_weight_error_enable = true;
            this.isSaved = false;
          }
         }
        else {
          this.required_fields_error_enable = true;
          this.isSaved = false;
      }
      },
      userPageViewTable() {
              if (this.currentCustomer === null) {
                  var userPageViewTableBody = {
                  pageName : 'addDependent',
                  typeOfUser: 'CUSTOMER'
                  }
              } else {
                  var userPageViewTableBody = {
                  pageName: 'addDependent',
                  typeOfUser: 'CUSTOMER',
                  token: this.currentCustomer
                  }
              }
              axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
              .catch((userPageViewTableError) => {
                  console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
              });
      },
    },
    mounted() {
        var userAgent = navigator.userAgent
        if(userAgent.includes("kbzpay")){
            this.show_header = false;
        }
        this.isMobile = this.$device.mobile;
        this.$i18n.locale = this.$store.state.locale;
        if(this.$i18n.locale == "en")
        {
            this.translation_enter_name = "Enter Name";
            this.translation_dob = "Enter Date Of Birth";
            this.translation_enter_email = "Enter Email";
            this.translation_enter_number = "Enter Phone Number";
            this.translation_enter_nrc = "Enter NRC";
            this.translation_in_lbs = "in lbs";
            this.translation_feet = "feet";
            this.translation_inches = "inches";
            document.title = "Add Dependent"
        }
        else
        {
            this.translation_enter_name = "အမည်";
            this.translation_dob = "မွေးနေ့";
            this.translation_enter_email = "အီးလ်မေး";
            this.translation_enter_number = "ဖုန်းနံပါတ်";
            this.translation_enter_nrc = "မှတ်ပုံတင်";
            this.translation_in_lbs = "ပေါင်";
            this.translation_feet = "ပေ";
            this.translation_inches = "လက်မ";
            document.title = "မှီခိုမိသားစုဝင် ထည့်မည်"
        }
        this.currentCustomer = this.$cookies.get("customerToken");
        if (!this.currentCustomer) {
            this.$router.push({
            name: "Login",
            });
        } else {
            var verifyCustomerTokenData = {
            token: this.currentCustomer,
            typeOfUser: "CUSTOMER",
            };
            axios.post(
                process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",
                verifyCustomerTokenData
            ).then((verifyCustomerTokenDataResponse) => {
                this.customer_name = verifyCustomerTokenDataResponse.data.data.customer_name;
                this.phoneNumber = verifyCustomerTokenDataResponse.data.data.phoneNumber;
                this.userPageViewTable();
            }).catch((verifyCustomerTokenDataError) => {
                if (verifyCustomerTokenDataError.response.status == 401) {
                    this.auth_error_enable = true;
                } else {
                    this.error_enable = true;
                }
            });
        }
    },
  };
  </script>
  
<style scoped>
@import '../../../src/scss/classes.scss';
@import '../../../src/scss/global.scss';
  .header {
    display: block;
    box-shadow: 0px 2px 15px 0px #00000026;
    padding: 16px 20px !important;
    background-color: white;
  }
</style>
  
<style>
  .font_size_12_400 {
    font-size: 14px;
    font-weight: 400;
  }
  .mx-input {
    height: 40px !important;
    border-bottom: 1px solid #ccc !important;
    border-radius: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  /* .v-picker--full-width {
    height: 500px;
  } */
  
  
  /* @media (min-width: 800px){
    .mainDivaddDependent{
     width: 800px !important;
     margin-left: auto !important ;
      margin-right: auto !important ;
    }
    .PageClass{
       margin-top: 25px !important;
    }
    } */
  
</style>
<style>
  .v-select__selection {
    line-height: 20px !important;
  }
  .v-select__slot.v-select__selection.v-select__selection--comma{
    line-height: 20px !important;
  }
</style>
  